// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { DEV_CONFIG } from './config';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const DEV_FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBPSsqa1QFcFrOXTCJYEVVMpBHDGJ9v7RQ',
  authDomain: 'araddin-med-dev.firebaseapp.com',
  projectId: 'araddin-med-dev',
  storageBucket: 'araddin-med-dev.appspot.com',
  messagingSenderId: '485188416255',
  appId: '1:485188416255:web:65dd75eba53b155d1792ca',
  measurementId: 'G-QXKZFSQM9T',
};

const PROD_FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCAuSqMrJ4D_zHRyw71kFD0n6F0-zR1Hk0',
  authDomain: 'araddin-med.firebaseapp.com',
  projectId: 'araddin-med',
  storageBucket: 'araddin-med.appspot.com',
  messagingSenderId: '1007317516904',
  appId: '1:1007317516904:web:012a4396ec7c0bae41f1dd',
  measurementId: 'G-3P8H98KGZQ',
};

const firebaseConfig = DEV_FIREBASE_CONFIG;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireStoreDB = getFirestore(app);

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export default fireStoreDB;

export const SignOut = async () => {
  try {
    const auth = getAuth();
    await signOut(auth);
  } catch (e) {
    console.log(e);
  }
};
