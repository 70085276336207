import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from './SearchBar';
import VideoList, { DummyVideoList } from './VideoList';
import {
  useData,
  fetchData,
  create_lectures_query,
  create_tags_query,
} from '../../services/api';

export default function Home() {
  const classes = useStyles();
  const { data: tagdata } = useData('tags', create_tags_query());
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const lectures = useQuery({
    queryKey: ['lectures'],
    queryFn: () => fetchData(create_lectures_query()),
  });
  const handleSearchSubmit = (searchTerm) => {
    setSearchTerm(searchTerm);
  };
  const toggleTag = (id) => {
    if (id === -1) {
      setSelectedTags([]);
    } else {
      setSelectedTags(
        selectedTags.includes(id)
          ? selectedTags.filter((tag) => tag !== id)
          : [...selectedTags, id],
      );
    }
  };

  const videos =
    lectures.data?.data
      .filter((lecture) =>
        lecture.title.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .filter((lecture) =>
        selectedTags.every((tag) =>
          lecture.tagList.map((tag) => tag.id).includes(tag),
        ),
      )
      .map((lecture) => ({
        ...lecture,
        tagTitle: lecture.tagList.map((tag) => tag.name),
      })) || [];

  return (
    <Box className={classes.root}>
      <Box className={classes.searchbarContainer}>
        <SearchBar
          onSearchSubmit={handleSearchSubmit}
          toggleTag={toggleTag}
          tags={tagdata ? tagdata.data : []}
          selectedTags={selectedTags}
        />
      </Box>
      <Box className={classes.videoListContainer}>
        {lectures.isLoading ? (
          <DummyVideoList />
        ) : (
          <VideoList videos={videos} />
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 32,
    [theme.breakpoints.down('xs')]: {
      margin: 16,
    },
  },
  videoListContainer: {
    marginTop: 16,
  },
}));
