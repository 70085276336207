import React, { useState } from 'react';
import {
  AppBar,
  Typography,
  Toolbar,
  Avatar,
  Link,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SignOut } from '../../services/firebase';
import ReactGA from 'react-ga4';

export default function Header({ user }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
    ReactGA.event('click', {
      action: 'avatar',
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleClose();
    localStorage.removeItem('token');
    SignOut();
    ReactGA.event('click', {
      action: 'sign_out',
    });
  };

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar variant="dense" className={classes.toolbarContainer}>
        <Typography variant="h6">
          <Link
            href="/"
            style={{ color: '#000' }}
            underline="none"
            onClick={() => {
              ReactGA.event('click', {
                action: 'go_home',
              });
            }}
          >
            ダッシュボード
          </Link>
        </Typography>
        {user && (
          <>
            <IconButton size="small" onClick={handleAvatarClick}>
              <Avatar>{user.email.charAt(0).toUpperCase()}</Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem onClick={handleSignOut}>サインアウト</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    color: '#000000',
    width: '100%',
    height: '48px',
  },
  toolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    flex: 1,
  },
}));
