import { useQuery } from '@tanstack/react-query';
import { CONFIG } from './config';

export function useData(
  queryKey,
  apiPath,
  enable = true,
  staleTime = 1 * 1000,
) {
  return useQuery([queryKey, apiPath], () => fetchData(apiPath), {
    enabled: enable,
    staleTime: staleTime,
  });
}

export function usePostData(queryKey, apiPath, data) {
  return useQuery([queryKey, apiPath], () => postData(apiPath, data));
}

function getHeader(token) {
  if (token === null) {
    return {
      'Content-Type': 'application/json',
      'x-api-key': CONFIG.API_KEY,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
  }
  return {
    'Content-Type': 'application/json',
    'x-api-key': CONFIG.API_KEY,
    'Araddin-Token': token,
  };
}

export function putData(path, data, token = null) {
  const header = getHeader(token);

  return fetch(path, {
    method: 'PUT',
    headers: header,
    body: JSON.stringify(data),
  });
}

export async function postData(path, data, token = null) {
  const header = getHeader(token);

  const res = await fetch(path, {
    method: 'POST',
    headers: header,
    body: JSON.stringify(data),
  });
  return res.json();
}

export const fetchData = async (path, token = null) => {
  const header = getHeader(token);
  const res = await fetch(path, {
    method: 'GET',
    headers: header,
  });
  return res.json();
};

export function create_user_query() {
  return `${CONFIG.API_BASE_URL}/me`;
}

export function create_lectures_query() {
  return `${CONFIG.API_BASE_URL}/lecture`;
}

export function create_tags_query() {
  return `${CONFIG.API_BASE_URL}/tag`;
}

export function create_lecture_query(id) {
  return `${CONFIG.API_BASE_URL}/lecture/${id}`;
}

export function create_sessions_query(lecture_id) {
  return `${CONFIG.API_BASE_URL}/lecture/${lecture_id}/session`;
}

export function create_session_query(lecture_id, session_id) {
  return `${CONFIG.API_BASE_URL}/lecture/${lecture_id}/session/${session_id}`;
}

export function create_checkitem_query(lecture_id, session_id, checkitem_id) {
  return `${CONFIG.API_BASE_URL}/lecture/${lecture_id}/session/${session_id}/check-item/${checkitem_id}`;
}

export function create_videos_query(id) {
  let query = '/videos';
  let args = [];

  args = args.concat(option_id(id));

  if (args.length > 0) {
    query += `?${args.join('&')}`;
  }

  return query;
}

export function create_chapters_query(id) {
  let query = '/chapters';
  let args = [];

  args = args.concat(option_id(id));

  if (args.length > 0) {
    query += `?${args.join('&')}`;
  }

  return query;
}

export function create_checklists_query(video_id) {
  let query = '/checklists';
  let args = [];

  args = args.concat(option_id(video_id));

  if (args.length > 0) {
    query += `?${args.join('&')}`;
  }

  return query;
}

export function create_video_upload_query(ext) {
  let query = `${CONFIG.API_BASE_URL}/file/upload`;
  let args = [];

  args = args.concat(option_ext(ext));

  if (args.length > 0) {
    query += `?${args.join('&')}`;
  }

  return query;
}

export function create_token_query(sessionId) {
  let query = `${CONFIG.API_BASE_URL}/token`;
  let args = [];

  args = args.concat(option_session_id(sessionId));

  if (args.length > 0) {
    query += `?${args.join('&')}`;
  }

  return query;
}

export function create_check_token_query() {
  return `${CONFIG.API_BASE_URL}/token/check`;
}

export function create_token_session_query(session_id) {
  return `${CONFIG.API_BASE_URL}/token/session/${session_id}`;
}

export function create_token_video_upload_query(ext) {
  let query = `${CONFIG.API_BASE_URL}/token/file/upload`;
  let args = [];

  args = args.concat(option_ext(ext));

  if (args.length > 0) {
    query += `?${args.join('&')}`;
  }

  return query;
}

function option_id(id) {
  const opt = [];
  if (id !== undefined) opt.push(`id=${id}`);

  return opt;
}

function option_ext(ext) {
  const opt = [];
  if (ext !== undefined) opt.push(`ext=${ext}`);

  return opt;
}

function option_session_id(sessionId) {
  const opt = [];
  if (sessionId !== undefined) opt.push(`sessionId=${sessionId}`);

  return opt;
}

export async function create_video_upload_link(sessionId) {
  const res = await postData(create_token_query(), {
    type: 'session',
    resources: { sessionId: sessionId },
  }).then((response) => {
    return response;
  });

  const url = new URL(window.location.href);
  const newUrl = `${url.origin}/mobile?token=`;

  return {
    token: res.token,
    expiredAt: res.expiredAt,
    url: newUrl + res.token,
  };
}

export async function uploadVideo(lecture_id, session_id, data) {
  const uploadPath = await fetchData(create_video_upload_query('webm')).then(
    (response) => {
      return response;
    },
  );
  await fetch(uploadPath.url, {
    method: 'PUT',
    body: data,
  });
  await putData(create_session_query(lecture_id, session_id), {
    file: { id: uploadPath.id },
  });
}

export async function upload_video_token(session_id, data, ext, token) {
  // 動画ファイルのアップロード
  const uploadPath = await fetchData(
    create_token_video_upload_query(ext),
    token,
  ).then((response) => {
    return response;
  });

  const uploadRes = await fetch(uploadPath.url, {
    method: 'PUT',
    body: data,
  });
  if (!uploadRes.ok) {
    return uploadRes;
  }

  const sessionUpdateRes = await putData(
    create_token_session_query(session_id),
    {
      file: { id: uploadPath.id },
    },
    token,
  );

  return sessionUpdateRes;
}
