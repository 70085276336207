import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './video.css';
import { setupVideoTracking } from '../../util/setupVideoTracking';
import ReactGA from 'react-ga4';

// Video component を responsive にするための設定
// https://stackoverflow.com/questions/24290484/video-js-responsive-not-working
window.VIDEOJS_NO_DYNAMIC_STYLE = true;

class VideoPlayer extends React.Component {
  componentDidMount() {
    this.player = videojs(
      this.videoNode,
      this.props.options,
      function onPlayerReady() {
        this.on('ended', () => {
          this.play();
        });
      },
    );
    this.player.controlBar.skipForward.el_.addEventListener('click', () => {
      ReactGA.event('video', {
        action: 'skip_forward',
      });
    });
    this.player.controlBar.skipBackward.el_.addEventListener('click', () => {
      ReactGA.event('video', {
        action: 'skip_backward',
      });
    });
    if (this.props.videoUpdated) {
      this.player.on('timeupdate', this.props.videoUpdated);
    }
    if (this.props.metadataLoaded) {
      this.player.on('loadedmetadata', this.props.metadataLoaded);
    }

    if (this.props.playerRef) {
      this.props.playerRef.current = this.player;
    }
    setupVideoTracking(this.videoNode, this.props);
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div className="video-js-responsive-container vjs-hd">
        <video ref={(node) => (this.videoNode = node)} className="video-js" />
      </div>
    );
  }
}

export default VideoPlayer;
