import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

export default function PrivateRoute() {
  const { currentUser } = getAuth();
  const location = useLocation();
  return currentUser !== null ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} />
  );
}
