import React from 'react';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Checkbox,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import ReactGA from 'react-ga4';
import { makeStyles } from '@material-ui/core/styles';
import RepeatIcon from '@material-ui/icons/Repeat';
import ColoredText from '../../util/ColoredText';

function Controller(props) {
  const { id, videoPlayFuncs, playingStatus } = props;
  const { playingChapter } = playingStatus ? playingStatus : {};
  const classes = useStyles();

  const handlePlay = () => {
    ReactGA.event('click', {
      action: 'start_loop_playback',
      id: props.chapter.id,
      context: props.mode,
    });
    videoPlayFuncs.jumpToChapter(id);
    videoPlayFuncs.setPlayingChapter(id);
    videoPlayFuncs.playVideo(id);
  };

  const handlePause = () => {
    ReactGA.event('click', {
      action: 'stop_loop_playback',
      id: props.chapter.id,
      context: props.mode,
    });
    videoPlayFuncs.setPlayingChapter(-1);
  };

  return id !== playingChapter ? (
    <IconButton
      variant="outlined"
      onClick={handlePlay}
      color="primary"
      className={classes.iconButton}
    >
      <RepeatIcon style={{ fontSize: 25 }} size="small" />
    </IconButton>
  ) : (
    <IconButton className={classes.iconButton} onClick={handlePause}>
      <RepeatIcon style={{ fontSize: 25 }} className={classes.icon} />
    </IconButton>
  );
}

export default function Chapter(props) {
  const {
    id,
    chapter,
    checklist,
    toggleChecklist,
    mode,
    onSelectChapter,
    selectedChapter,
  } = props;
  const checklistSize = checklist.length;
  const checkedlistSize = checklist.filter((c) => c.checked).length;

  return (
    <Box id={`chapter-list-${id}`} p={1}>
      <Box direction="row" bgcolor={'#F6F6FF'}>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <ColoredText text={`${id + 1}. ${chapter.title}`} />
          </Grid>
          <Grid item xs={1}>
            {props.videoPlayFuncs && <Controller {...props} />}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box pl={2} color={'text.secondary'}>
          <Typography align="left">
            項目数: {checkedlistSize}/{checklistSize}
          </Typography>
        </Box>
        <Box sx={{ height: 1, backgroundColor: '#EEEEEE' }} />
      </Box>
      {checklist?.map((checklistitem) => (
        <ListItem
          key={checklistitem.id}
          button
          ContainerComponent="div"
          onClick={() => {
            onSelectChapter(checklistitem);
          }}
          style={{
            backgroundColor:
              selectedChapter?.id === checklistitem.id ? '#F6F6FF' : '#FFFFFF',
            borderLeft:
              selectedChapter?.id === checklistitem.id
                ? '2px solid #000AFF'
                : '2px solid #FFFFFF',
          }}
        >
          <ListItemText variant="body2" align="left">
            <Grid item xs={11}>
              <ColoredText text={checklistitem.title} />
            </Grid>
          </ListItemText>
          {mode === 'review' && (
            <ListItemSecondaryAction>
              <Checkbox
                color="primary"
                checked={checklistitem.checked}
                onChange={(event) => {
                  if (event.target.checked) {
                    ReactGA.event('click', {
                      action: 'check_chapter_item',
                      label: checklistitem.title,
                      id: checklistitem.id,
                      context: mode,
                    });
                  } else {
                    ReactGA.event('click', {
                      action: 'uncheck_chapter_item',
                      label: checklistitem.title,
                      id: checklistitem.id,
                      context: mode,
                    });
                  }
                  toggleChecklist(checklistitem.id, event.target.checked);
                }}
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  iconButton: {
    width: 28,
    height: 28,
    border: '1px solid #000AFF',
    backgroundColor: '#F6F6FF',
  },
  icon: {
    color: '#888888',
  },
}));
