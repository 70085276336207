import React, { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import VideoDropZone from '../VideoDropZone';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import VideoPlayer from '../VideoPlayer';

import {
  fetchData,
  create_check_token_query,
  upload_video_token,
} from '../../services/api';

export default function VideoUploadView(props) {
  const [mode, setMode] = useState('checktoken');
  const [token, setToken] = useState(null); // urlParam['token']
  const [session, setSession] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [expiredDate, setExpiredDate] = useState(null);
  const [untilExpired, setUntilExpired] = useState(null);
  const accept = {
    'video/mp4': ['.mp4', '.MP4', '.mov', '.MOV', '.avi', '.webm'],
  };

  function useURLParam() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const urlParam = useURLParam();
  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    let countDownInterval = null;
    if (expiredDate !== null) {
      countDownInterval = setInterval(() => {
        const now = new Date();
        const diff = expiredDate - now;
        setUntilExpired(diff);
      }, 500);
    }
    return () => {
      if (countDownInterval != null) clearInterval(countDownInterval);
    };
  });

  const checkToken = async () => {
    const token = urlParam.get('token');

    fetchData(create_check_token_query(), token).then((res) => {
      console.log('tokenCheck', res);
      if (res.success) {
        setExpiredDate(new Date(res.expiredAt));
        setSession(res.tokenData.resources.session);
        setMode('select');
        setToken(token);
        setErrorMsg('');
      } else {
        setMode('error');
        setErrorMsg('無効なURLです. 再度QRコードを読み取ってください.');
      }
    });
  };

  switch (mode) {
    case 'checktoken': {
      return <CheckTokenView />;
    }
    case 'select': {
      const props = {
        untilExpired: untilExpired,
        session: session,
        setRecordedVideo: setVideoData,
        setMode: setMode,
        accept: accept,
      };
      return <SelectModeView {...props} />;
    }
    case 'confirm': {
      const props = {
        videoData: videoData,
        setMode: setMode,
      };
      return <ConfirmModeView {...props} />;
    }
    case 'upload': {
      const props = {
        videoData: videoData,
        setMode: setMode,
        setErrorMsg: setErrorMsg,
        token: token,
        session: session,
      };
      return <UploadModeView {...props} />;
    }
    case 'done': {
      const props = {};
      return <DoneModeView {...props} />;
    }
    default:
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30vh',
          }}
        >
          <Typography>{errorMsg}</Typography>
        </Box>
      );
  }
}

function CheckTokenView(_props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Typography>URLを検証中...</Typography>
      <CircularProgress />
    </Box>
  );
}

function SelectModeView(props) {
  const { setRecordedVideo, setMode, accept, untilExpired, session } = props;
  const setVideo = (video) => {
    setRecordedVideo(video);
    setMode('confirm');
  };

  // 日時の差分を時分秒で表示する
  const dateDiffStr = (untilExpired) => {
    const diffDate = new Date(untilExpired);
    const result = {
      hour: diffDate.getHours(),
      min: diffDate.getMinutes(),
      sec: diffDate.getSeconds(),
    };
    return `${result.hour}時間 ${result.min}分 ${result.sec}秒`;
  };
  const diff = dateDiffStr(untilExpired);

  return (
    <Box sx={{ margin: 5 }}>
      <Typography variant="h5">{session.lecture.title}</Typography>
      <Typography>トークン失効まで</Typography>
      <Typography>{diff}</Typography>
      <VideoDropZone setRecordedVideo={setVideo} accept={accept} />
    </Box>
  );
}

function ConfirmModeView(props) {
  const { videoData, setMode } = props;

  const playerOptions = {
    autoplay: true,
    muted: false,
    controls: true,
    playsinline: true,
    controlBar: {
      captionsButton: false,
      chaptersButton: false,
      subtitlesButton: false,
      remainingTimeDisplay: false,
      progressControl: {
        seekBar: true,
      },
      fullscreenToggle: true,
      pictureInPictureToggle: false,
    },
    inactivityTimeout: 0,
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
    sources: [
      {
        src: URL.createObjectURL(videoData),
        type: 'video/mp4',
      },
    ],
  };

  const handleRetry = () => {
    setMode('select');
  };

  const handleUpload = () => {
    setMode('upload');
  };

  return (
    <Box sx={{ alignItems: 'center' }}>
      <VideoPlayer context="uploadVideo" options={playerOptions} />
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <Box sx={{ margin: 10 }}>
          <Button variant="contained" onClick={handleRetry} color="primary">
            Retry
          </Button>
        </Box>
        <Box sx={{ margin: 10 }}>
          <Button variant="contained" onClick={handleUpload} color="primary">
            Upload
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function UploadModeView(props) {
  const { videoData, setMode, token, setErrorMsg, session } = props;

  useEffect(() => {
    (async () => {
      upload_video_token(session.id, videoData, 'mp4', token).then((res) => {
        console.log('upload', res);
        if (res.ok) {
          setMode('done');
        } else {
          setMode('error');
          setErrorMsg(
            'アップロードに失敗しました. 再度QRコードを読み取ってください.',
          );
        }
      });
    })();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30vh',
      }}
    >
      <CircularProgress />
      <Typography>動画をアップロードしています</Typography>
    </Box>
  );
}

function DoneModeView(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30vh',
      }}
    >
      <Typography>動画のアップロードが完了しました</Typography>
      <Typography>このページを閉じて、PCのページを更新してください</Typography>
    </Box>
  );
}
