export const DEV_CONFIG = {
  API_BASE_URL:
    'https://ncyzkk47g5.execute-api.ap-northeast-1.amazonaws.com/v1',
  API_KEY: 'hjwvk9faTJ4sp5pTqJLiN2bpCFxHLMjX8DWtnOLn',
};

export const PROD_CONFIG = {
  API_BASE_URL:
    'https://4j8wpinofj.execute-api.ap-northeast-1.amazonaws.com/v1',
  API_KEY: 'RvqG4otTD9UxFbPzlzMJ53nklK51aQV1wdV254G5',
};

export const CONFIG = DEV_CONFIG;
