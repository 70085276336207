import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function VideoDropZone(props) {
  const classes = useStyles();

  const { setRecordedVideo, accept } = props;
  const [errorMsg, setErrorMsg] = useState('');
  const [isConverting] = useState(false);

  const onDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      setErrorMsg(
        'ファイルサイズが大きすぎます。3GB以下のファイルを選択してください。',
      );
      return;
    }

    const reader = new FileReader();
    setErrorMsg('読み込み中...');
    reader.onload = (e) => {
      const size = acceptedFiles[0].size;
      const slice = acceptedFiles[0].slice(0, size, acceptedFiles[0].type);
      setRecordedVideo(slice);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  return isConverting ? (
    <Box>
      <CircularProgress />
      <Typography>動画ファイルを変換しています...</Typography>
    </Box>
  ) : (
    <Dropzone onDrop={onDrop} accept={accept} maxSize={3 * 1024 * 1024 * 1024}>
      {({ getRootProps, getInputProps }) => (
        <Box className={classes.dropzoneContainer}>
          <Box
            {...getRootProps({ style: { height: 240 } })}
            className={classes.dropzone}
          >
            <input {...getInputProps()} />
            <Typography>
              ここに動画ファイルをドラッグ&ドロップするか、クリックしてファイルをえらんでください。
            </Typography>
            {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          </Box>
        </Box>
      )}
    </Dropzone>
  );
}

const useStyles = makeStyles((theme) => ({
  dropzoneContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    padding: 20,
  },
  dropzone: {
    height: '100%',
    border: '4px dashed #888',
    borderRadius: '10px',
    backgroundColor: '#eee',
    alignItems: 'center',
    textAlign: 'center',
    padding: 20,
  },
}));
