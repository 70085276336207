export const CREATE_SESSION = 'CREATE_SESSION';
export const SET_VIDEO_URL = 'SET_VIDEO_URL';
export const SET_CHECKITEM = 'SET_CHECKITEM';

export function createSession(session) {
  return {
    type: CREATE_SESSION,
    session,
  };
}

export function setVideoUrl(sessionId, fileid, filepath, fileurl) {
  return {
    type: SET_VIDEO_URL,
    sessionId,
    fileid,
    filepath,
    fileurl,
  };
}

export function setCheckItem(sessionId, checkItemId) {
  return {
    type: SET_CHECKITEM,
    sessionId,
    checkItemId,
  };
}
