import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SearchBox from './SearchBox';
import TagList from './TagList';

export default function SearchBar(props) {
  const { onSearchSubmit } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <SearchBox onSearchSubmit={onSearchSubmit} width={'100%'} />
        </Grid>
        <Grid item xs={12}>
          <TagList {...props} />
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
