import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
} from '@material-ui/core';
import ReactGA from 'react-ga4';

import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

function Tags({ tags }) {
  const classes = useStyles();
  return tags.length === 0 ? null : (
    <Box className={classes.tagContainer}>
      {tags.map((tag) => (
        <Chip key={tag} label={tag} variant="outlined" size="small" />
      ))}
    </Box>
  );
}

function VideoCard({ video }) {
  const classes = useStyles();
  return (
    <Link
      to={`/training?id=${video.id}`}
      className={classes.video}
      onClick={() => {
        ReactGA.event('click', {
          action: 'select_lecture',
          label: video.title,
          id: video.id,
        });
      }}
    >
      <Card className={classes.videoCard}>
        <CardMedia className={classes.media} image={video.thumbnailPath} />
        <CardContent className={classes.videoCardContent}>
          <Typography
            variant="body1"
            className={classes.videoCardTitle}
            align="left"
          >
            {video.title}
          </Typography>
          <Tags tags={video.tagTitle} />
        </CardContent>
      </Card>
    </Link>
  );
}

export const DummyVideoList = () => {
  const classes = useStyles();
  return (
    <Box className={classes.videoContainer}>
      {[1, 2, 3].map((key) => (
        <Card className={classes.video} key={key}>
          <Skeleton className={classes.media} animation="wave" variant="rect" />
          <CardContent>
            <Skeleton
              animation="wave"
              variant="text"
              className={classes.videoCardContent}
            />
            <Skeleton
              animation="wave"
              variant="text"
              className={classes.videoCardContent}
            />
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default function VideoList({ videos }) {
  const classes = useStyles();
  return (
    <Box className={classes.videoContainer}>
      {videos.map((video) => (
        <VideoCard video={video} key={video.id} />
      ))}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    gap: 12,
  },
  video: {
    display: 'flex',
    flexDirection: 'column',
  },
  videoCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  videoCardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  videoCardTitle: {
    flex: 1,
  },
  media: {
    height: 0,
    width: '100%',
    paddingTop: '56.25%', // 16:9
  },
  tagContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    flexWrap: 'wrap',
  },
}));
