import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {
  fetchData,
  postData,
  putData,
  create_lecture_query,
  create_sessions_query,
  create_session_query,
  create_checkitem_query,
} from '../../services/api';
import Review from '../Review';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Skeleton } from '@material-ui/lab';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Training() {
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const urlParam = useMemo(() => new URLSearchParams(search), [search]);
  const id = urlParam.get('id');
  const lecture = useQuery({
    queryKey: ['lecture', id],
    queryFn: () => fetchData(create_lecture_query(id)),
  });
  const session = useQuery({
    queryKey: ['session', id],
    queryFn: async () => {
      const sessions = await fetchData(create_sessions_query(id));
      if (sessions.data.length === 0) {
        return await postData(create_sessions_query(id), {});
      }
      const newestSessionMeta = sessions.data.reduce((prev, current) =>
        prev.createdAt > current.createdAt ? prev : current,
      );
      const newestSessionId = newestSessionMeta.id;
      return await fetchData(create_session_query(id, newestSessionId));
    },
  });
  const chapters = useMemo(
    () =>
      lecture.data?.chapterList.map((chapter) => ({
        ...chapter,
        checkItemList: chapter.checkItemList.map((checkItem) => ({
          ...checkItem,
          checked:
            session.data?.checkItemStatusList.find(
              (item) => item.checkItemId === checkItem.id,
            )?.done ?? false,
        })),
      })) || [],
    [lecture, session],
  );
  const [onNotification, setOnNotification] = useState(false);
  async function create_new_session() {
    await postData(create_sessions_query(id), {}).then(() => {
      queryClient.invalidateQueries(['session', id]);
    });
  }

  if (lecture.isLoading || session.isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: 64,
            alignItems: 'center',
            padding: '0 60px',
          }}
        >
          <Box clone sx={{ height: 24, width: '100%' }}>
            <Skeleton animation="wave" variant="rect" />
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              clone
              sx={{
                height: 0,
                paddingTop: '56.25%', // 16:9
                width: '100%',
              }}
            >
              <Skeleton animation="wave" variant="rect" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 12px',
              }}
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <Box
                  key={index}
                  clone
                  sx={{
                    height: 24,
                    width: '100%',
                    marginBottom: 1,
                  }}
                >
                  <Skeleton animation="wave" variant="text" />
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const toggleChecklist = (checkitemId, checked) => {
    const session = queryClient.getQueryData(['session', id]);
    const newChecklist = session.checkItemStatusList.find(
      (checklist) => checklist.checkItemId === checkitemId,
    )
      ? session.checkItemStatusList.map((checkItemStatus) => ({
          ...checkItemStatus,
          done:
            checkItemStatus.checkItemId === checkitemId
              ? checked
              : checkItemStatus.done,
        }))
      : [
          ...session.checkItemStatusList,
          {
            sessionId: session.id,
            checkItemId: checkitemId,
            done: checked,
          },
        ];
    queryClient.setQueryData(['session', id], {
      ...session,
      checkItemStatusList: newChecklist,
    });
    putData(create_checkitem_query(id, session.id, checkitemId), {
      done: checked,
    });
    setOnNotification(true);
  };

  return (
    <>
      <Review
        video={{ ...lecture.data.video, title: lecture.data.title }}
        chapters={chapters}
        session={session.data}
        toggleChecklist={toggleChecklist}
        createNewSession={create_new_session}
      />
      <Snackbar
        open={onNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={3000}
        onClose={() => setOnNotification(false)}
      >
        <Alert onClose={() => setOnNotification(false)} severity="success">
          保存しました
        </Alert>
      </Snackbar>
    </>
  );
}
