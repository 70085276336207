import { useState } from 'react';

export default function useVideoHandler(playerRef) {
  const [videoStatus, setVideoStatus] = useState({
    isPaused: true,
    currentTime: 0,
    duration: 0,
    volume: 1,
    playbackRate: 1,
    currentChapter: -1,
  });

  const handleVideoStatusChange = () => {
    const currentTime = playerRef.current.currentTime();

    setVideoStatus((prev) => {
      return prev;
    });

    setVideoStatus((prev) => {
      return {
        ...prev,
        currentTime: currentTime,
        duration: playerRef.current.duration(),
        isPaused: playerRef.current.paused(),
        volume: playerRef.current.volume(),
        playbackRate: playerRef.current.playbackRate(),
      };
    });
  };

  const playVideo = () => {
    if (playerRef.current) {
      playerRef.current.play();
      videoStatus.isPaused = false;
    }
  };

  const pauseVideo = () => {
    if (playerRef.current) {
      playerRef.current.pause();
      videoStatus.isPaused = true;
    }
  };

  const jumpTo = (time) => {
    if (playerRef.current) {
      playerRef.current.currentTime(time);
    }
  };

  const updatePlaybackRate = (rate) => {
    if (!playerRef.current) return;

    playerRef.current.playbackRate(rate);
  };

  const updateVolume = (volume) => {
    if (!playerRef.current) return;

    playerRef.current.volume(volume);
  };

  const setCurrentChapter = (chapterId) => {
    console.log('setCurrentChapter', chapterId);
    setVideoStatus({ ...videoStatus, currentChapter: chapterId });
  };

  const video_functions = {
    handleVideoStatusChange: handleVideoStatusChange,
    playVideo: playVideo,
    pauseVideo: pauseVideo,
    jumpTo: jumpTo,
    updatePlaybackRate: updatePlaybackRate,
    updateVolume: updateVolume,
    setCurrentChapter: setCurrentChapter,
  };

  return {
    videoFunctions: video_functions,
    videoStatus: videoStatus,
  };
}
