import {
  SET_CHECKITEM_STATUS,
  UPDATE_CHECKITEM_STATUS,
} from '../actions/checkItem';

const INITIAL_STATE = {
  isLoading: true,
};

export default function checkItemReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CHECKITEM_STATUS:
      return { ...action.status, isLoading: false };
    case UPDATE_CHECKITEM_STATUS:
      return update_checkitem_status(state, action.checkItemId, action.checked);
    default:
      break;
  }
  return state;
}

function update_checkitem_status(state, checkItemId, checked) {
  return {
    ...state,
    checked: checked,
  };
}
