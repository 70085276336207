import React, { useRef, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Box,
  Grid,
  Typography,
  Button,
  Modal,
  CircularProgress,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga4';
import { QRCodeCanvas } from 'qrcode.react';
import LaptopIcon from '@material-ui/icons/Laptop';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import VideoPlayer from '../VideoPlayer';
import ChapterList from '../ChapterList';
import WebCam from '../WebCam';
import VideoDropZone from '../VideoDropZone';
import useVideoHandler from '../VideoHandler/useVideoHandler';
import { uploadVideo, create_video_upload_link } from '../../services/api';
import { Link } from 'react-router-dom';
import ColoredText from '../../util/ColoredText';

function UploadView(props) {
  const { setRecordedVideo, accept, session_id } = props;
  const [device, setDevice] = useState('pc');

  const { data: uploadLink, isLoading: isLinkLoading } = useQuery(
    ['upload_link', session_id],
    () => create_video_upload_link(session_id),
  );

  return (
    <Box>
      <ToggleButtonGroup
        value={device}
        exclusive
        onChange={(_, device) => {
          setDevice(device);
        }}
      >
        <ToggleButton value="pc">
          <LaptopIcon />
        </ToggleButton>
        <ToggleButton value="mobile">
          <PhoneAndroidIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      {device === 'pc' ? (
        <VideoDropZone setRecordedVideo={setRecordedVideo} accept={accept} />
      ) : (
        <Box sx={{ m: 14 }}>
          <QRCodeCanvas
            value={isLinkLoading ? '' : uploadLink.url}
            fgColor={isLinkLoading ? '#FFFFFF' : '#000000'}
          />
          <Typography>
            スマートフォンでQRコードを読み取って動画をアップロードしてください
          </Typography>
        </Box>
      )}
    </Box>
  );
}

function isPracticeMode(mode) {
  return mode === 'practice';
}
function isExamMode(mode) {
  return ['prepare', 'record', 'confirm', 'upload'].includes(mode);
}
function isReviewMode(mode) {
  return mode === 'review';
}

export default function Review(props) {
  const classes = useStyles();

  const recordedVideoRef = useRef(null);
  const referenceVideoRef = useRef(null);
  const webcamRef = useRef(null);

  const videoURL = props.session?.file?.url;
  const [mode, setMode] = useState(
    props.session?.file?.url ? 'review' : 'practice',
  );

  const referenceHandler = useVideoHandler(referenceVideoRef);
  const recordedHandler = useVideoHandler(recordedVideoRef);

  const [playingStatus, setPlayingStatus] = useState({
    playingChapter: -1,
    playList: [],
  });

  const [currentChapter, setCurrentChapter] = useState(-1);

  const [recordedVideo, setRecordedVideo] = useState(null);
  const [uploadingVideo, setUploadingVideo] = useState(false);

  useEffect(() => {
    if (!referenceVideoRef.current) return;
    const currentTime = referenceVideoRef.current.currentTime();
    const { playingChapter, playList } = playingStatus;
    let _currentChapter = -1;
    if (playingChapter >= 0) {
      _currentChapter = playingChapter;
    } else {
      for (let i = 0; i < chapters.length; i++) {
        if (playList.length > 0 && !playList.includes(i)) continue;

        if (
          chapters[i].startSeconds <= currentTime &&
          currentTime < chapters[i].endSeconds
        ) {
          _currentChapter = i;
        }
      }
    }
    if (_currentChapter >= 0) {
      if (
        currentTime > chapters[_currentChapter].endSeconds ||
        currentTime < chapters[_currentChapter].startSeconds
      ) {
        jumpToChapter(_currentChapter);
      }
    } else if (playList.length > 0) {
      if (!playList.includes(_currentChapter)) {
        let nextChapter = (_currentChapter + 1) % chapters.length;
        while (!playList.includes(nextChapter)) {
          nextChapter = (nextChapter + 1) % chapters.length;
        }
        jumpToChapter(nextChapter);
      }
    }
    setCurrentChapter(_currentChapter);
    return () => {};
  }, [referenceHandler.videoStatus, playingStatus, setCurrentChapter]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    let _currentChapter;
    setCurrentChapter((prev) => {
      _currentChapter = prev;
      return prev;
    });
    let _playingStatus;
    setPlayingStatus((prev) => {
      _playingStatus = prev;
      return prev;
    });
    const { playingChapter } = _playingStatus;
    if (event.key === 'ArrowRight') {
      ReactGA.event('keyboard', {
        action: 'forward_chapter',
      });
      if (_currentChapter >= 0 && _currentChapter < chapters.length - 1) {
        jumpToChapter(_currentChapter + 1);
        if (playingChapter >= 0) {
          setPlayingChapter(_currentChapter + 1);
        }
      }
    }
    if (event.key === 'ArrowLeft') {
      ReactGA.event('keyboard', {
        action: 'backward_chapter',
      });
      if (_currentChapter > 0) {
        jumpToChapter(_currentChapter - 1);
        if (playingChapter > 0) {
          setPlayingChapter(_currentChapter - 1);
        }
      }
    }
    if (event.key === ' ') {
      if (referenceHandler.videoStatus.isPaused) {
        referenceHandler.videoFunctions.playVideo(
          referenceVideoRef,
          playingChapter,
        );
        ReactGA.event('keyboard', {
          action: 'play_video',
          label: props.session.lecture.title,
          id: props.session.lecture.id,
          context: mode,
        });
      } else {
        referenceHandler.videoFunctions.pauseVideo(referenceVideoRef);
        ReactGA.event('keyboard', {
          action: 'pause_video',
          label: props.session.lecture.title,
          id: props.session.lecture.id,
          context: mode,
        });
      }
      event.preventDefault();
    }
  };

  const handleModeChange = (newMode) => {
    if (referenceVideoRef !== null && referenceVideoRef.current !== null) {
      referenceHandler.videoFunctions.pauseVideo();
    }
    setMode(newMode);
    stopPreview();

    const videoData = webcamRef.current?.getVideoSrc();
    if (newMode === 'review' && videoData) {
      const lectureId = props.session.lecture.id;
      setUploadingVideo(true);
      uploadVideo(lectureId, props.session.id, videoData).finally(() => {
        setUploadingVideo(false);
      });
    }
  };

  const { video, chapters, createNewSession } = props;

  const playerOptions = {
    // autoplay: false,
    muted: false,
    controls: true,
    playsinline: true,
    inactivityTimeout: 0,
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
    sources: [
      {
        src: video.url,
        type: 'video/mp4',
        title: video.title,
        id: props.session.lecture.id,
      },
    ],
  };

  const jumpToChapter = (chapterId, start = true) => {
    const chapter = chapters[chapterId];
    referenceHandler.videoFunctions.jumpTo(
      start ? chapter.startSeconds : chapter.endSeconds,
    );
  };

  const setPlayingChapter = (chapterId) => {
    setPlayingStatus((prev) => {
      return { ...prev, playingChapter: chapterId };
    });
  };

  const handleChapterCheck = (chapterId, flag) => {
    const { playList } = playingStatus;
    if (flag) {
      playList.push(chapterId);
    } else {
      const index = playList.indexOf(chapterId);
      if (index > -1) {
        playList.splice(index, 1);
      }
    }
    setPlayingStatus({
      ...playingStatus,
      playList: playList.sort((a, b) => a - b),
    });
  };

  const handleCheckAll = (flag) => {
    setPlayingStatus({
      ...playingStatus,
      playList: flag ? [...Array(chapters.length).keys()] : [],
    });
  };

  const playVideo = (chapterId) => {
    setPlayingChapter(chapterId);
    referenceHandler.videoFunctions.playVideo(referenceVideoRef, chapterId);
  };

  const startRecord = () => {
    webcamRef.current.startRecording();
  };

  const stopRecord = () => {
    webcamRef.current.stopRecording();
  };

  const createFilename = () => {
    const pad2 = (n) => {
      return n < 10 ? `0${n}` : n;
    };
    const currentdate = new Date();
    const fileName = `${
      currentdate.getFullYear().toString() +
      pad2(currentdate.getMonth() + 1) +
      pad2(currentdate.getDate())
    }_${pad2(currentdate.getHours())}${pad2(currentdate.getMinutes())}${pad2(
      currentdate.getSeconds(),
    )}`;
    return fileName;
  };

  const handleDownload = () => {
    const url = URL.createObjectURL(recordedVideo);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;

    const filename = createFilename();
    a.download = `video_${filename}.webm`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const startPreview = () => {
    if (webcamRef.current) {
      webcamRef.current.startPreview();
    }
  };

  const stopPreview = () => {
    if (webcamRef.current) {
      webcamRef.current.stopPreview();
    }
  };

  const videoUploaded = (data) => {
    setRecordedVideo(data);
    setMode('confirm');

    if (webcamRef.current) {
      webcamRef.current.setVideoSrc(data);
    }
    startPreview();
  };

  const refVideoPlayFuncs = {
    ...referenceHandler.videoFunctions,
    playVideo: playVideo,
    jumpToChapter: jumpToChapter,
    setPlayingChapter: setPlayingChapter,
    handlePlayList: handleChapterCheck,
    handleCheckAll: handleCheckAll,
    startRecord: startRecord,
    stopRecord: stopRecord,
    handleDownload: handleDownload,
    startPreview: startPreview,
    stopPreview: stopPreview,
    setRecordedVideo: setRecordedVideo,
  };

  const recordedVideoView = () => {
    if (recordedVideo === null && videoURL === null) {
      return <VideoDropZone setRecordedVideo={setRecordedVideo} />;
    }
    const src = recordedVideo
      ? { src: URL.createObjectURL(recordedVideo), type: 'video/mp4' } // https://www.tutorialspoint.com/how-to-play-quicktime-mov-files-using-the-video-js-player
      : { src: videoURL };

    const options = {
      ...playerOptions,
      sources: [src],
      controlBar: {
        skipButtons: {
          forward: 5,
          backward: 5,
        },
      },
    };
    return (
      <VideoPlayer
        context="recordedVideo"
        options={options}
        playerRef={recordedVideoRef}
        videoUpdated={recordedHandler.videoFunctions.handleVideoStatusChange}
      />
    );
  };

  const [isOpenSessionClearModal, setIsOpenSessionClearModal] = useState(false);
  const createNewSessionMutation = useMutation({
    mutationFn: createNewSession,
    onSuccess: () => {
      setRecordedVideo(null);
      setIsOpenSessionClearModal(false);
      setMode('practice');
    },
  });

  const [isOpenMovingReviewModeModal, setIsOpenMovingReviewModeModal] =
    useState(false);

  useEffect(() => {
    if (isReviewMode(mode)) {
      return setIsOpenMovingReviewModeModal(false);
    }
    if ((!!recordedVideo || !!videoURL) && !isPracticeMode(mode)) {
      return setIsOpenMovingReviewModeModal(true);
    }
  }, [mode, recordedVideo, videoURL]);

  const [selectedChapter, setSelectedChapter] = useState();

  const videoView = () => {
    if (isReviewMode(mode)) {
      return (
        <Grid container direction="row">
          <Grid item xs={12} sm={6}>
            <Box style={{ padding: 1 }}>{recordedVideoView()}</Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box style={{ padding: 1 }}>
              <VideoPlayer
                context="referenceVideo"
                options={playerOptions}
                playerRef={referenceVideoRef}
                videoUpdated={
                  referenceHandler.videoFunctions.handleVideoStatusChange
                }
              />
            </Box>
          </Grid>
        </Grid>
      );
    }
    if (isPracticeMode(mode)) {
      return (
        <>
          <Grid item xs={12}>
            <Box style={{ position: 'relative' }}>
              <VideoPlayer
                context="practiceVideo"
                options={playerOptions}
                playerRef={referenceVideoRef}
                videoUpdated={
                  referenceHandler.videoFunctions.handleVideoStatusChange
                }
              />
            </Box>
          </Grid>
        </>
      );
    }
    if (isExamMode(mode)) {
      const ExamControls = {
        prepare: (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                handleModeChange('record');
                startRecord();
                ReactGA.event('click', {
                  action: 'start_recording',
                  id: props.session.lecture.id,
                });
              }}
              color="primary"
              style={{ marginRight: '8px' }}
            >
              録画開始
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                handleModeChange('upload');
                ReactGA.event('click', {
                  action: 'upload_video',
                  id: props.session.lecture.id,
                });
              }}
              color="primary"
            >
              動画のアップロード
            </Button>
          </>
        ),
        upload: (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                handleModeChange('prepare');
                ReactGA.event('click', {
                  action: 'cancel_upload',
                  id: props.session.lecture.id,
                });
              }}
              color="primary"
            >
              戻る
            </Button>
          </>
        ),
        record: (
          <Button
            className={classes.modeChangeButton}
            variant="contained"
            onClick={() => {
              handleModeChange('confirm');
              stopRecord();
              // FIXME: webcam の録画完了をイベントハンドラを登録するなりして待てるようにする
              window.setTimeout(() => {
                startPreview();
                const videoData = webcamRef.current.getVideoSrc();
                setRecordedVideo(videoData);
              }, 100);
              ReactGA.event('click', {
                action: 'stop_recording',
                id: props.session.lecture.id,
              });
            }}
            color="primary"
          >
            録画停止
          </Button>
        ),
        confirm: (
          <Button
            variant="outlined"
            onClick={() => {
              console.log(props);
              handleModeChange('prepare');
              setRecordedVideo(null);
              ReactGA.event('click', {
                action: 'restart_recording',
                id: props.session.lecture.id,
              });
            }}
            color="primary"
          >
            撮り直す
          </Button>
        ),
      };
      return (
        <Grid item xs={12}>
          <Box style={{ display: 'none' }}>
            <VideoPlayer
              options={playerOptions}
              playerRef={referenceVideoRef}
              videoUpdated={
                referenceHandler.videoFunctions.handleVideoStatusChange
              }
            />
          </Box>
          <Box style={{ position: 'relative' }}>
            <Box
              style={{
                display: mode !== 'upload' ? 'block' : 'none',
                width: '80%',
                margin: 'auto',
              }}
            >
              <WebCam ref={webcamRef} />
            </Box>
            {mode === 'upload' && (
              <UploadView
                setRecordedVideo={videoUploaded}
                accept={{
                  'video/mp4': [
                    '.mp4',
                    '.MP4',
                    '.mov',
                    '.MOV',
                    '.avi',
                    '.webm',
                  ],
                }}
                session_id={props.session.id}
              />
            )}
            <Box alignSelf="center" sx={{ width: '100%', height: '32px' }}>
              {ExamControls[mode]}
            </Box>
          </Box>
        </Grid>
      );
    }
  };

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: '64px', px: 2 }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          maxWidth="50%"
        >
          <Link
            to="/"
            onClick={() => {
              ReactGA.event('click', {
                action: 'go_back_home',
              });
            }}
          >
            <IconButton size="small">
              <KeyboardArrowLeftRoundedIcon style={{ fontSize: '24px' }} />
            </IconButton>
          </Link>
          <Typography variant="body1" noWrap>
            {video.title}
          </Typography>
        </Box>
        <Box>
          <Button
            color="primary"
            className={classes.modeButton}
            variant={isPracticeMode(mode) ? 'contained' : 'outlined'}
            onClick={() => {
              ReactGA.event('click', {
                action: 'mode',
                label: 'practice',
                context: mode,
              });
              if (isPracticeMode(mode)) {
                return;
              }
              if (isExamMode(mode)) {
                setMode('practice');
                return;
              }
              if (isReviewMode(mode)) {
                setIsOpenSessionClearModal(true);
                return;
              }
            }}
          >
            Practice
          </Button>
          <Button
            color="primary"
            className={classes.modeButton}
            variant={isExamMode(mode) ? 'contained' : 'outlined'}
            disabled={isReviewMode(mode)}
            onClick={() => {
              ReactGA.event('click', {
                action: 'mode',
                label: 'exam',
                context: mode,
              });
              if (isExamMode(mode)) {
                return;
              }
              handleModeChange('prepare');
            }}
          >
            Exam
          </Button>
          <Button
            color="primary"
            className={classes.modeButton}
            variant={isReviewMode(mode) ? 'contained' : 'outlined'}
            disabled={
              isPracticeMode(mode) || (recordedVideo === null && !videoURL)
            }
            onClick={() => {
              ReactGA.event('click', {
                action: 'mode',
                label: 'review',
                context: mode,
              });
              if (isReviewMode(mode)) {
                return;
              }
              handleModeChange('review');
            }}
          >
            Review
          </Button>
        </Box>
      </Box>
      <Box
        className={
          isReviewMode(mode) ? classes.reviewContainer : classes.container
        }
      >
        <Box
          className={
            ['prepare', 'record', 'confirm'].includes(mode)
              ? classes.webcam
              : classes.video
          }
        >
          {videoView()}
        </Box>
        {(() => {
          if (isExamMode(mode)) {
            return null;
          }

          if (isPracticeMode(mode)) {
            return (
              <Box className={classes.chapter}>
                <ChapterList
                  chapters={chapters}
                  mode={mode}
                  videoPlayFuncs={refVideoPlayFuncs}
                  {...referenceHandler.videoStatus}
                  playingStatus={playingStatus}
                  toggleChecklist={props.toggleChecklist}
                  currentChapter={currentChapter}
                  onSelectChapter={(chapter) => {
                    setSelectedChapter((prev) =>
                      prev === chapter ? null : chapter,
                    );
                    ReactGA.event('click', {
                      action: 'select_chapter_item',
                      label: chapter.title,
                      id: chapter.id,
                      context: mode,
                    });
                  }}
                  selectedChapter={selectedChapter}
                />
              </Box>
            );
          }

          return (
            <Box display="flex" flexDirection="row" minHeight={0} flex={1}>
              <Box flex={1}>
                {selectedChapter && isReviewMode(mode) && (
                  <Box
                    style={{
                      backgroundColor: '#F6F6FF',
                    }}
                  >
                    <ColoredText text={selectedChapter.title} />
                  </Box>
                )}
              </Box>
              <Box flex={1}>
                <ChapterList
                  style={{ overflowY: 'scroll', maxHeight: '100%' }}
                  chapters={chapters}
                  mode={mode}
                  videoPlayFuncs={refVideoPlayFuncs}
                  {...referenceHandler.videoStatus}
                  playingStatus={playingStatus}
                  toggleChecklist={props.toggleChecklist}
                  currentChapter={currentChapter}
                  onSelectChapter={(chapter) => {
                    setSelectedChapter((prev) =>
                      prev === chapter ? null : chapter,
                    );
                    ReactGA.event('click', {
                      action: 'select_chapter_item',
                      label: chapter.title,
                      id: chapter.id,
                      context: mode,
                    });
                  }}
                  selectedChapter={selectedChapter}
                />
              </Box>
            </Box>
          );
        })()}
      </Box>
      <Modal
        open={isOpenSessionClearModal}
        onClose={() => {
          if (!createNewSessionMutation.isLoading) {
            setIsOpenSessionClearModal(false);
          }
        }}
      >
        <Box
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          className={classes.modal}
        >
          <Typography component="p">
            Practiceモードに戻って 練習をやり直しますか？
            <br />
            その場合、新しい練習記録データが作成されます。
          </Typography>
          {createNewSessionMutation.isLoading ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 2,
              }}
            >
              <CircularProgress size={40} />
            </Box>
          ) : (
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className={classes.modalButton}
                variant="contained"
                onClick={() => {
                  createNewSessionMutation.mutate();
                  setRecordedVideo(null);
                  ReactGA.event('click', {
                    action: 'confirm_practice',
                  });
                }}
                color="primary"
              >
                はい
              </Button>
              <Button
                className={classes.modalButton}
                variant="outlined"
                onClick={() => {
                  setIsOpenSessionClearModal(false);
                  ReactGA.event('click', {
                    action: 'cancel_practice',
                  });
                }}
                color="primary"
              >
                キャンセル
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
      <Modal
        open={isOpenMovingReviewModeModal}
        onClose={() => {
          setIsOpenMovingReviewModeModal(false);
        }}
      >
        <Box
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          className={classes.modal}
        >
          <Typography component="p">Reviewモードに移りますか？</Typography>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className={classes.modalButton}
              variant="contained"
              onClick={() => {
                ReactGA.event('click', {
                  action: 'mode',
                  label: 'review',
                  context: mode,
                });
                if (isReviewMode(mode)) {
                  return;
                }
                handleModeChange('review');
              }}
              color="primary"
            >
              はい
            </Button>
            <Button
              className={classes.modalButton}
              variant="outlined"
              onClick={() => {
                setIsOpenMovingReviewModeModal(false);
              }}
              color="primary"
            >
              キャンセル
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={uploadingVideo}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        message={
          <Box display="flex" flexDirection="row">
            <Box>動画をアップロード中です。タブを閉じないでください。</Box>
            <CircularProgress size={20} color="secondary" />
          </Box>
        }
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  reviewContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    minHeight: 0,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  webcam: {
    justifyContent: 'center',
    width: '80%',
    height: '80%',
    padding: '4px 10%',
    [theme.breakpoints.down('xs')]: {
      flex: 0,
    },
  },
  video: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flex: 0,
    },
  },
  reviewChapter: {
    flex: 1,
    paddingLeft: '50%',
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      paddingLeft: 0,
    },
    minWidth: 375,
    overflow: 'scroll',
  },
  chapter: {
    flex: 1,
    minWidth: 375,
    overflow: 'scroll',
    minHeight: 0,
  },
  dropzoneContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  dropzone: {
    height: '100%',
    border: '4px dashed #888',
    borderRadius: '10px',
    backgroundColor: '#eee',
    alignItems: 'center',
    textAlign: 'center',
    padding: 20,
  },
  modal: {
    position: 'absolute',
    backgroundColor: '#fff',
    borderRadius: 25,
    padding: '50px',
  },
  modalButton: {
    margin: 20,
    height: 40,
    width: 110,
    borderRadius: 25,
  },
  modeButton: {
    margin: 4,
    borderRadius: 25,
    [theme.breakpoints.down('xs')]: {
      minWidth: '48px',
      maxWidth: '48px',
      fontSize: '0.5rem',
    },
  },
}));
