import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  Paper,
  Modal,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { postData, create_user_query } from '../services/api';

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../services/firebase';

const RULE_TEXT =
  '【データ利用に関する同意書】\n\
このアプリをご利用いただきありがとうございます。私たちは、アプリの改善や新機能の開発など、より良いサービスを提供するためにユーザのデータを活用することを検討しています。以下の内容をご確認いただき、データ利用に関する同意をいただければ幸いです。\n\
\n\
データ利用の目的:\n\
ユーザのデータは、アプリの改善や新機能の開発、匿名統計データの作成など、アプリの品質向上に関連する目的、及び学術発表のために使用される可能性があります。\n\
\n\
データの種類:\n\
私たちは、以下のようなユーザデータを収集することがあります（例: ユーザの操作履歴、デバイス情報、地理的位置情報など）。ただし、これらのデータは匿名化され、個別のユーザを特定することはできません。\n\
\n\
データの匿名化とセキュリティ:\n\
ユーザのデータは、個人を特定できない形で匿名化されます。私たちは、データの安全性を確保するために適切な技術とセキュリティ対策を講じます。\n\
\n\
データの第三者提供:\n\
ユーザのデータは、法的要件に従う場合や信頼できるパートナーとの提携に基づいて、匿名化された形で第三者に提供されることがあります。ただし、個別のユーザを特定することはできません。\n\
\n\
同意する場合は、以下の同意文にチェックを入れてください。';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [mode, setMode] = useState('login');
  const [agree, setAgree] = useState(false);
  const [openRule, setOpenRule] = useState(false);
  const navigate = useNavigate();

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    try {
      signInWithEmailAndPassword(auth, username, password)
        .then((userCredential) => {
          localStorage.setItem('token', userCredential.user.accessToken);
          navigate('/');
        })
        .catch((error) => {
          console.log(error);
          setErrorMsg('ユーザー名またはパスワードが間違っています。');
        });
      setErrorMsg('');
    } catch (error) {
      setErrorMsg('ユーザー名またはパスワードが間違っています。');
    }
  };

  const handleSubmitSignUp = async (event) => {
    event.preventDefault();
    try {
      createUserWithEmailAndPassword(auth, username, password)
        .then((userCredential) => {
          localStorage.setItem('token', userCredential.user.accessToken);
          postData(create_user_query(), { name: username }).then((userRes) => {
            navigate('/');
          });
        })
        .catch((error) => {
          console.log(error);
          setErrorMsg('ユーザー名またはパスワードを正しく設定してください。');
        });
      setErrorMsg('');
    } catch (error) {
      setErrorMsg('ユーザー名またはパスワードを正しく設定してください。');
    }
  };

  const handleToNewAccount = async (event) => {
    event.preventDefault();
    setMode('signup');
  };

  const handleToLogin = async (event) => {
    event.preventDefault();
    setMode('login');
  };

  const handleClose = () => {
    setOpenRule(false);
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        style={{
          marginTop: '80px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ margin: '10px' }}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {mode === 'login' ? 'ログイン' : 'アカウント登録'}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="ユーザー名"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {mode === 'signup' && (
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => setOpenRule(true)}
              style={{ marginTop: '30px', mb: 2 }}
              startIcon={
                agree ? (
                  <CheckCircleOutlineIcon style={{ color: '#000AFF' }} />
                ) : (
                  <CheckCircleOutlineIcon />
                )
              }
            >
              利用規約を読む
            </Button>
          )}
          <Dialog open={openRule} onClose={handleClose}>
            <DialogContent>
              <Box>
                <Typography variant="h6" align="center" component="h2">
                  利用規約
                </Typography>
                <Divider />
                <Paper
                  style={{
                    marginTop: '30px',
                    padding: '20px',
                    width: '100%',
                    maxHeight: '400px',
                    maxWidth: '650px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                  }}
                >
                  {RULE_TEXT.split('\n').map((text, index) => {
                    return (
                      <Typography key={index} variant="body2" style={{ mt: 2 }}>
                        {text}
                        <br />
                      </Typography>
                    );
                  })}
                </Paper>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  <RadioGroup
                    row
                    value={agree ? 'agree' : 'disagree'}
                    onChange={(event) =>
                      setAgree(event.target.value === 'agree')
                    }
                  >
                    <FormControlLabel
                      value="agree"
                      control={<Radio color="primary" />}
                      label="同意する"
                    />
                    <FormControlLabel
                      value="disagree"
                      control={<Radio color="primary" />}
                      label="同意しない"
                    />
                  </RadioGroup>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '15px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    閉じる
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={mode === 'login' ? handleSubmitLogin : handleSubmitSignUp}
            disabled={!agree && mode === 'signup'}
            style={{ marginTop: '30px', mb: 2 }}
          >
            {mode === 'login' ? 'ログイン' : '新規作成'}
          </Button>
          <Button
            style={{ marginTop: '20px', mb: 2 }}
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={mode === 'login' ? handleToNewAccount : handleToLogin}
          >
            {mode === 'login' ? 'アカウント新規作成はこちら' : 'ログイン画面へ'}
          </Button>
          {errorMsg && (
            <Alert style={{ marginTop: '10px' }} severity="error">
              {errorMsg}
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  height: '600px',
  bgcolor: 'background.paper',
  border: '1px solid #555',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};
