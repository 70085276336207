import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

export default function Theme({ children }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#000AFF',
        contrastText: '#fff',
      },
      secondary: {
        main: '#FFF',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          color: '#000AFF',
          fontSize: 36,
          padding: 0,
        },
      },
      MuiTab: {
        root: {
          fontSize: 20,
          backgroundColor: '#ab93ca',
          color: '#fff',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          '&.Mui-selected': {
            backgroundColor: '#000AFF',
            color: '#fff',
          },
        },
      },
      MuiButton: {
        root: {
          backgroundColor: '#000AFF',
          color: '#fff',
        },
        outlined: {
          backgroundColor: '#fff',
          color: '#000AFF',
          borderColor: '#000AFF',
        },
      },
      MuiAppBar: {
        root: {
          backgroundColor: '#fff',
          color: '#000000',
          height: 64,
          width: '100%',
        },
      },
      MuiCard: {
        root: {
          borderRadius: 8,
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
