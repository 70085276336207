import React, { Component, createRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Webcam from 'react-webcam';
import fixWebmDuration from 'fix-webm-duration';

const styles = () => ({
  webcam: {
    background: 'black',
    width: '100%',
    height: '100%',
  },
});

const createFilename = () => {
  const pad2 = (n) => {
    return n < 10 ? `0${n}` : n;
  };
  const currentdate = new Date();
  const fileName = `${
    currentdate.getFullYear().toString() +
    pad2(currentdate.getMonth() + 1) +
    pad2(currentdate.getDate())
  }_${pad2(currentdate.getHours())}${pad2(currentdate.getMinutes())}${pad2(
    currentdate.getSeconds(),
  )}`;
  return fileName;
};

class WebCam extends Component {
  constructor(props) {
    super(props);
    this.webcamRef = createRef();
    this.canvasRef = createRef();
    this.mediaRecorderRef = createRef();
    this.state = {
      cameras: [],
      camera: null,
      mode: 'stream',
      recordedChunks: [],
      recordStartTime: 0,
      recordEndTime: 0,
      blob: null,
    };
  }

  componentDidMount() {
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      const devices = mediaDevices.filter(({ kind }) => kind === 'videoinput');
      this.setState({ cameras: devices });
      if (devices.length) {
        this.setState({ camera: devices[0] });
      }
    });
  }

  onUserMedia = (stream) => {
    this.webcamRef.current.video.onloadedmetadata = () => {};
    this.mediaRecorderRef.current = new MediaRecorder(
      this.webcamRef.current.stream,
      {
        mimeType: 'video/webm',
      },
    );
  };

  setVideoSrc = (data) => {
    this.setState({ blob: data });
  };

  getVideoSrc = () => {
    if (this.state.recordedChunks.length === 0 && this.state.blob === null) {
      return null;
    }
    if (this.state.blob === null) {
      const duration = this.state.recordEndTime - this.state.recordStartTime;
      const blob = new Blob(this.state.recordedChunks, { type: 'video/webm' });
      fixWebmDuration(blob, duration).then((fixedBlob) => {
        this.setState({ blob: fixedBlob });
      });
      return blob;
    }
    return this.state.blob;
  };

  startRecording = () => {
    this.setState({
      mode: 'record',
      recordedChunks: [],
      recordStartTime: Date.now(),
      blob: null,
    });
    this.mediaRecorderRef.current.addEventListener(
      'dataavailable',
      this.handleDataAvailable,
    );
    this.mediaRecorderRef.current.start();
  };

  stopRecording = () => {
    this.mediaRecorderRef.current.stop();
    this.setState({ mode: 'stream', recordEndTime: Date.now() });
  };

  startPreview = () => {
    this.setState({ mode: 'playback' });
    const videodata = this.getVideoSrc();
    const url = URL.createObjectURL(videodata);
    const preview = document.querySelector('#videoRecorded');
    preview.src = url;
  };

  stopPreview = () => {
    this.setState({ mode: 'stream' });
  };

  handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      this.setState((prevState) => ({
        recordedChunks: prevState.recordedChunks.concat(data),
      }));
    }
  };

  handleDownload = () => {
    const { recordedChunks } = this.state;
    if (recordedChunks.length) {
      const duration = this.state.recordEndTime - this.state.recordStartTime;
      const blob = new Blob(recordedChunks, { type: 'video/webm' });
      fixWebmDuration(blob, duration).then((fixedBlob) => {
        const url = URL.createObjectURL(fixedBlob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        const filename = createFilename();
        a.download = `video_${filename}.webm`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }
  };

  render() {
    const { mode, camera } = this.state;
    const { classes } = this.props;
    return (
      <>
        <video
          id="videoRecorded"
          autoPlay
          muted
          loop
          playsInline
          controls
          style={{
            outline: 'solid 5px #00ff00',
            display: mode === 'playback' ? 'block' : 'none',
          }}
          className={classes.webcam}
        />
        <Webcam
          audio={false}
          className={classes.webcam}
          ref={this.webcamRef}
          style={{
            display: mode === 'playback' ? 'none' : 'block',
            outline:
              mode === 'record' ? 'solid 5px #ff0000' : 'solid 0px #329eff',
          }}
          screenshotFormat="image/jpeg"
          onUserMedia={this.onUserMedia}
          videoConstraints={{
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            deviceId: camera !== null ? camera.deviceId : null,
          }}
        />
      </>
    );
  }
}

export default withStyles(styles)(WebCam);
