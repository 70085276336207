import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Box, makeStyles } from '@material-ui/core';
import { Provider } from 'react-redux';
import reducer from '../store/reducers/';
import { createStore } from 'redux';

import './App.scss';
import PrivateRoute from './PrivateRoute';
import Home from './Home';
import Header from './Header';
import Training from './Training';
import VideoUploadView from './VideoUploadView';
import Theme from '../Theme/Theme';
import usePageTracking from '../services/usePageTracking';
import ReactGA from 'react-ga4';

import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
} from 'firebase/auth';
import Login from './Login';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: true,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const styles = useStyles();
  const auth = getAuth();
  setPersistence(auth, browserSessionPersistence);

  usePageTracking();

  const [user, setUser] = useState(auth.currentUser);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ReactGA.set({ userId: user?.uid });
  }, [user]);

  const store = createStore(reducer);

  onAuthStateChanged(auth, async (user) => {
    setUser(user);
    setIsLoading(false);

    if (user) {
      const token = await user.getIdToken(true);
      localStorage.setItem('token', token);
    }
  });

  if (isLoading) {
    return <div />;
  }
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Theme
          children={
            <div className="App">
              <Box className={styles.container}>
                <Box className={styles.header}>
                  <Header user={user} />
                </Box>
                <Box className={styles.main}>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/training"
                      element={<PrivateRoute currentUser={user} />}
                    >
                      <Route path="/training" element={<Training />} />
                    </Route>
                    <Route
                      path="/"
                      element={<PrivateRoute currentUser={user} />}
                    >
                      <Route path="/" element={<Home />} />
                    </Route>
                    <Route path="/mobile" element={<VideoUploadView />} />
                  </Routes>
                </Box>
              </Box>
            </div>
          }
        />
      </QueryClientProvider>
    </Provider>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  header: {},
  main: {
    flex: 1,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
}));
