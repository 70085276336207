import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import ReactGA from 'react-ga4';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 600,
  },
  searchInput: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
}));

const SearchBox = ({ onSearchSubmit }) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    onSearchSubmit(searchQuery);
  };
  return (
    <div className={classes.searchBar}>
      <TextField
        label="キーワード"
        variant="outlined"
        size="small"
        value={searchQuery}
        onChange={handleSearchChange}
        className={classes.searchInput}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            ReactGA.event('keyboard', {
              action: 'search',
            });
            handleSearchSubmit(e);
          }
        }}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={(event) => {
          handleSearchSubmit(event);
          ReactGA.event('click', {
            action: 'search',
          });
        }}
      >
        検索
      </Button>
    </div>
  );
};

SearchBox.propTypes = {
  onSearchSubmit: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};

export default SearchBox;
