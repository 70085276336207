export const SET_CHECKITEM_STATUS = 'SET_CHECKITEM_STATUS';
export const UPDATE_CHECKITEM_STATUS = 'UPDATE_CHECKITEM_STATUS';

export function setCheckItemStatus(checkItemId, status) {
  return {
    type: SET_CHECKITEM_STATUS,
    checkItemId,
    status,
  };
}

export function updateCheckItemStatus(checkItemId, checked) {
  return {
    type: UPDATE_CHECKITEM_STATUS,
    checkItemId,
    checked,
  };
}
