import ReactGA from 'react-ga4';

export const setupVideoTracking = (videoElement, props) => {
  videoElement.addEventListener('play', () => {
    ReactGA.event('video', {
      action: 'play',
      label: props.options.sources[0].title,
      id: props.options.sources[0].id,
      context: props.context,
    });
  });

  videoElement.addEventListener('pause', () => {
    ReactGA.event('video', {
      action: 'pause',
      label: props.options.sources[0].title,
      id: props.options.sources[0].id,
      context: props.context,
    });
  });

  videoElement.addEventListener('ended', () => {
    ReactGA.event('video', {
      action: 'ended',
      label: props.options.sources[0].title,
      id: props.options.sources[0].id,
      context: props.context,
    });
  });
};
