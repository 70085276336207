import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import Chapter from './Chapter';
import ReactGA from 'react-ga4';

export default function ChapterList(props) {
  const { chapters, currentChapter, videoPlayFuncs, playingStatus, style } =
    props;
  const classes = useStyles();

  return (
    <Box style={style} id="chapter-list">
      <Button
        className={classes.button}
        variant="outlined"
        onClick={() => {
          if (currentChapter > 0) {
            videoPlayFuncs.jumpToChapter(currentChapter - 1);
            if (playingStatus.playingChapter > 0) {
              videoPlayFuncs.setPlayingChapter(currentChapter - 1);
            }
          }
          ReactGA.event('click', {
            action: 'backward_chapter',
          });
        }}
      >
        戻る
      </Button>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={() => {
          if (currentChapter >= 0 && currentChapter < chapters.length - 1) {
            videoPlayFuncs.jumpToChapter(currentChapter + 1);
            if (playingStatus.playingChapter >= 0) {
              videoPlayFuncs.setPlayingChapter(currentChapter + 1);
            }
          }
          ReactGA.event('click', {
            action: 'forward_chapter',
          });
        }}
      >
        進む
      </Button>
      {chapters.map((chapter, index) => {
        if (currentChapter >= 0 && index !== currentChapter) {
          return null;
        }
        return (
          <Chapter
            {...props}
            key={index}
            id={index}
            chapter={chapter}
            checklist={chapter.checkItemList}
          />
        );
      })}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 4,
    borderRadius: 25,
  },
}));
