import { CREATE_SESSION, SET_VIDEO_URL } from '../actions/session';

const INITIAL_STATE = {
  isLoading: true,
};

export default function sessionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_SESSION:
      return { ...action.session, isLoading: false };
    case SET_VIDEO_URL:
      return set_video_url(
        state,
        action.sessionId,
        action.fileid,
        action.filepath,
        action.fileurl,
      );
    default:
      return state;
  }
}

function set_video_url(state, sessionId, id, path, url) {
  if (state.id !== sessionId) {
    return state;
  }

  return {
    ...state,
    file: {
      id: id,
      path: path,
      url: url,
    },
  };
}
