import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga4';

export default function TagList(props) {
  const { toggleTag, tags, selectedTags } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Chip
        size="small"
        label={'すべて'}
        color="primary"
        onClick={() => {
          toggleTag(-1);
          ReactGA.event('click', {
            action: 'tag',
            label: 'すべて',
          });
        }}
        variant={selectedTags.length === 0 ? 'default' : 'outlined'}
      />
      {tags?.map((tag) => (
        <Chip
          size="small"
          label={tag.name}
          key={tag.id}
          color="primary"
          onClick={() => {
            toggleTag(tag.id);
            ReactGA.event('click', {
              action: 'tag',
              label: tag.name,
            });
          }}
          variant={selectedTags.includes(tag.id) ? 'default' : 'outlined'}
        />
      ))}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
    width: '100%',
  },
}));
